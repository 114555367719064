<template>
  <div v-if="permission.access">
    <validation-observer ref="loginValidation">
      <b-form
        class="auth-login-form mt-2"
        @submit.prevent
        enctype="multipart/form-data"
      >
        <div class="row">
          <div class="col-md-8">
            <b-card>
              <div class="row">
                <div class="col-md-6">
                  <!-- email -->
                  <b-form-group label="Name" label-for="name">
                    <validation-provider
                      #default="{ errors }"
                      name="name"
                      rules="required"
                    >
                      <b-form-input
                        id="name"
                        v-model="form.name"
                        :state="errors.length > 0 ? false : null"
                        name="name"
                        placeholder="Enter Your Name"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
                <div class="col-md-6">
                  <!-- email -->
                  <b-form-group label="Designation" label-for="designation">
                    <validation-provider
                      #default="{ errors }"
                      name="designation"
                      rules="required"
                    >
                      <b-form-input
                        id="designation"
                        v-model="form.designation"
                        :state="errors.length > 0 ? false : null"
                        name="designation"
                        placeholder="Enter Your Designation"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
                <div class="col-md-4">
                  <b-form-group
                    label="Select Department"
                    label-for="department_id"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="department_id"
                      rules="required"
                    >
                      <v-select
                        id="department_id"
                        v-model="form.department_id"
                        placeholder="Select Department"
                        :options="departments"
                        label="name_en"
                        :reduce="(option) => option.id"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
                <div class="col-md-4">
                  <!-- email -->
                  <b-form-group
                    label="Training Experience"
                    label-for="training_experience"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="training_experience"
                      rules="required"
                    >
                      <b-form-input
                        id="training_experience"
                        v-model="form.training_experience"
                        :state="errors.length > 0 ? false : null"
                        name="training_experience"
                        placeholder="Enter Your Training Experience"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
                <div class="col-md-4">
                  <!-- email -->
                  <b-form-group
                    label="No. Of Student"
                    label-for="no_of_student"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="no_of_student"
                      rules="required"
                    >
                      <b-form-input
                        id="no_of_student"
                        v-model="form.no_of_student"
                        :state="errors.length > 0 ? false : null"
                        name="no_of_student"
                        placeholder="Enter Your No. Of Student"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
              </div>
              <b-form-group
                label="Select Marketplaces"
                label-for="marketplaces"
              >
                <validation-provider
                  #default="{ errors }"
                  name="marketplaces"
                  rules="required"
                >
                  <v-select
                    id="marketplaces"
                    v-model="form.marketplaces"
                    placeholder="Select Marketplaces"
                    multiple
                    :options="marketplaces"
                    label="name"
                    :reduce="(option) => option.id"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-card>
            <b-card>
              <b-card-title> Achievements Gallery </b-card-title>
              <b-form-group>
                <b-form-file
                  multiple
                  id="student_work"
                  accept=".jpg, .png, .gif"
                  @change="achievementsImage($event)"
                  placeholder="Select Achievements Image"
                  drop-placeholder="Drop file here..."
                />
              </b-form-group>
              <div>
                <div
                  class="d-inline-block image_div"
                  v-for="(image, index) in show_images"
                  :key="index"
                  v-if="show_images.length"
                >
                  <div
                    class="image_delete"
                    @click="PermanentDeleteImage(image.id)"
                  >
                    X
                  </div>
                  <b-img
                    :src="image.link"
                    height="100"
                    width="100"
                    class="rounded mr-2 mb-1"
                  />
                </div>
                <div
                  class="d-inline-block image_div"
                  v-for="(image, index) in images"
                  :key="index"
                  v-if="images.length"
                >
                  <div class="image_delete" @click="deleteImage(index)">X</div>
                  <b-img
                    :src="image"
                    height="100"
                    width="100"
                    class="rounded mr-2 mb-1"
                  />
                </div>
              </div>
            </b-card>
            <b-card>
              <b-card-title> Specializatized On </b-card-title>
              <b-form-group>
                <div
                  class="row mb-1"
                  v-for="(specialization, index) in form.specializations"
                  :key="index"
                  v-if="form.specializations.length"
                >
                  <div class="col-md-10 col-9">
                    <validation-provider #default="{ errors }" rules="required">
                      <b-form-input
                        v-model="specialization.item"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Enter Your Specialization"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </div>
                  <div class="col-md-2 col-3">
                    <b-button
                      v-if="index == 0"
                      variant="primary"
                      class="w-100"
                      type="button"
                      @click="addSpecializationTopic()"
                    >
                      Add More
                    </b-button>
                    <b-button
                      v-if="index != 0"
                      variant="danger"
                      class="w-100"
                      type="button"
                      @click="removeSpecializationTopic(index)"
                    >
                      Delete
                    </b-button>
                  </div>
                </div>
              </b-form-group>
            </b-card>
            <b-card>
              <b-card-title> Educational Qualification </b-card-title>
              <b-form-group>
                <div
                  class="row mb-1"
                  v-for="(education, index) in form.educations"
                  :key="index"
                  v-if="form.educations.length"
                >
                  <div class="col-md-10 col-9">
                    <validation-provider #default="{ errors }" rules="required">
                      <b-form-input
                        v-model="education.item"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Enter Your Educational Qualification"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </div>
                  <div class="col-md-2 col-3">
                    <b-button
                      v-if="index == 0"
                      variant="primary"
                      class="w-100"
                      type="button"
                      @click="addEducationsTopic()"
                    >
                      Add More
                    </b-button>
                    <b-button
                      v-if="index != 0"
                      variant="danger"
                      class="w-100"
                      type="button"
                      @click="removeEducationsTopic(index)"
                    >
                      Delete
                    </b-button>
                  </div>
                </div>
              </b-form-group>
            </b-card>
            <b-card>
              <b-card-title> Work Experience </b-card-title>
              <b-form-group>
                <div
                  class="row mb-1"
                  v-for="(experience, index) in form.experiences"
                  :key="index"
                  v-if="form.experiences.length"
                >
                  <div class="col-md-10 col-9">
                    <validation-provider #default="{ errors }" rules="required">
                      <b-form-input
                        v-model="experience.item"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Enter Your Work Experience"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </div>
                  <div class="col-md-2 col-3">
                    <b-button
                      v-if="index == 0"
                      variant="primary"
                      class="w-100"
                      type="button"
                      @click="addExperiencesTopic()"
                    >
                      Add More
                    </b-button>
                    <b-button
                      v-if="index != 0"
                      variant="danger"
                      class="w-100"
                      type="button"
                      @click="removeExperiencesTopic(index)"
                    >
                      Delete
                    </b-button>
                  </div>
                </div>
              </b-form-group>
            </b-card>
          </div>

          <div class="col-md-4">
            <b-card>
              <h4 class="mb-1">Teacher Image</h4>
              <div>
                <b-img
                  v-if="form.is_head == true"
                  :src="teacher_image"
                  height="441"
                  width="300"
                  class="rounded mr-2 mb-1 mb-md-0"
                />
                <b-img
                  v-if="form.is_head != true"
                  :src="teacher_image"
                  height="300"
                  width="300"
                  class="rounded mr-2 mb-1 mb-md-0"
                />
              </div>
              <b-form-group
                class="mt-2"
                label="Select Image"
                label-for="teacher_image"
              >
                <b-form-file
                  id="teacher_image"
                  accept=".jpg, .png, .gif"
                  @change="loadImage($event)"
                  placeholder="Choose a file or drop it here..."
                  drop-placeholder="Drop file here..."
                />
              </b-form-group>
            </b-card>

            <b-card>
              <b-card-title> Status Section </b-card-title>

              <b-form-checkbox
                name="is_head"
                class="mr-5"
                v-model="form.is_head"
                switch
                inline
              >
                Is Department Head
              </b-form-checkbox>
              <b-form-checkbox
                name="status"
                v-model="form.status"
                switch
                inline
              >
                Status
              </b-form-checkbox>
            </b-card>
          </div>
        </div>

        <div class="mb-5">
          <b-button type="submit" variant="primary" @click="validationForm">
            Save
          </b-button>
        </div>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BRow,
  BCol,
  BButton,
  BMediaBody,
  BMediaAside,
  BMedia,
  BFormFile,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardTitle,
  BImg,
  BForm,
  BFormTextarea,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";

import vSelect from "vue-select";
export default {
  components: {
    BCard,
    BMedia,
    BFormFile,
    BFormTextarea,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BMediaAside,
    BMediaBody,
    vSelect,
  },
  data() {
    return {
      required,
      email,
      snowOption: {
        minHeight: 200,
      },
      form: {
        name: "",
        designation: "",
        department_id: null,
        training_experience: "",
        no_of_student: "",
        marketplaces: [],
        specializations: [],
        educations: [],
        experiences: [],
        teacher_image: null,
        images: [],
        status: true,
        is_head: false,
      },
      teacher_image: this.$store.state.base_url + "images/course/default.png",
      images: [],
      show_images: [],
      permission: {
        access: false,
      },
    };
  },
  mounted() {
    this.Permission();
    this.edit();
    this.$store.dispatch("getMarketplace");
    this.$store.dispatch("getDepartment");
  },
  methods: {
    async edit() {
      const teacher = await this.callApi(
        "post",
        "/app/teacher/edit/" + this.$route.params.id,
        this.search
      );
      if (teacher.status == 200) {
        this.form.name = teacher.data.teacher.name;
        this.form.designation = teacher.data.teacher.designation;
        this.form.department_id = teacher.data.teacher.course_department_id;
        this.form.training_experience =
          teacher.data.teacher.training_experience;
        this.form.no_of_student = teacher.data.teacher.no_of_student;

        teacher.data.teacher.marketplaces.forEach((marketplace) => {
          this.form.marketplaces.push(marketplace.id);
        });

        teacher.data.teacher.specializations.forEach((specialization) => {
          this.form.specializations.push({
            item: specialization.title,
          });
        });

        teacher.data.teacher.educations.forEach((education) => {
          this.form.educations.push({
            item: education.title,
          });
        });

        teacher.data.teacher.experiences.forEach((experience) => {
          this.form.experiences.push({
            item: experience.title,
          });
        });

        this.teacher_image =
          this.$store.state.base_url + teacher.data.teacher.image;

        teacher.data.teacher.achievements.forEach((image) => {
          this.show_images.push({
            id: image.id,
            link: this.$store.state.base_url + image.image,
          });
        });

        this.form.is_head = teacher.data.teacher.is_head == 1 ? true : false;
        this.form.status = teacher.data.teacher.status == 1 ? true : false;
      }
    },
    async PermanentDeleteImage(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't to delete it!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .post("/app/teacher/delete/achievement/" + id)
            .then((res) => {
              this.s(res.data.message);
              this.show_images = [];

              res.data.images.forEach((image) => {
                this.show_images.push({
                  id: image.id,
                  link: this.$store.state.base_url + image.image,
                });
              });
            })
            .catch((e) => {
              if (e.response.status === 422) {
                if (e.response.data.errors.name_en) {
                  this.e(e.response.data.errors.name_en[0]);
                }
                if (e.response.data.errors.video_thumbnail) {
                  this.e(e.response.data.errors.video_thumbnail[0]);
                }
              }
            });
        }
      });
    },
    async Permission() {
      const permissions = await this.callApi("post", "/app/permission");
      permissions.data.permissions.forEach((permission) => {
        if (permission.slug == "teacher.edit") {
          this.permission.access = true;
        }
      });
    },
    addSpecializationTopic() {
      this.form.specializations.push({
        item: "",
      });
    },
    removeSpecializationTopic(index) {
      this.form.specializations.splice(index, 1);
    },
    addEducationsTopic() {
      this.form.educations.push({
        item: "",
      });
    },
    removeEducationsTopic(index) {
      this.form.educations.splice(index, 1);
    },
    addExperiencesTopic() {
      this.form.experiences.push({
        item: "",
      });
    },
    removeExperiencesTopic(index) {
      this.form.experiences.splice(index, 1);
    },
    deleteImage(index) {
      this.form.images.splice(index, 1);
      this.images.splice(index, 1);
    },
    achievementsImage(e) {
      e.target.files.forEach((data) => {
        this.form.images.push(data);
        let reader = new FileReader();
        reader.onload = (e) => {
          this.images.push(e.target.result);
        };
        reader.readAsDataURL(data);
      });
    },
    loadImage(e) {
      this.form.teacher_image = e.target.files[0];
      let file = e.target.files[0];
      let reader = new FileReader();
      reader.onload = (e) => {
        this.teacher_image = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    add() {
      const fd = new FormData();
      fd.append("name", this.form.name);
      fd.append("designation", this.form.designation);
      fd.append("department_id", this.form.department_id);
      fd.append("training_experience", this.form.training_experience);
      fd.append("no_of_student", this.form.no_of_student);

      for (let i = 0; i < this.form.marketplaces.length; i++) {
        let marketplace = this.form.marketplaces[i];
        fd.append("marketplaces[" + i + "]", marketplace);
      }

      var self = this;
      if (this.form.images.length) {
        for (let i = 0; i < this.form.images.length; i++) {
          let file = self.form.images[i];
          fd.append("images[" + i + "]", file);
        }
      }

      for (let i = 0; i < this.form.specializations.length; i++) {
        let module = self.form.specializations[i];
        fd.append("specializations[" + i + "]", module.item);
      }

      for (let i = 0; i < this.form.educations.length; i++) {
        let education = self.form.educations[i];
        fd.append("educations[" + i + "]", education.item);
      }

      for (let i = 0; i < this.form.experiences.length; i++) {
        let experience = self.form.experiences[i];
        fd.append("experiences[" + i + "]", experience.item);
      }

      if (this.form.teacher_image) {
        fd.append("teacher_image", this.form.teacher_image);
      }

      fd.append("status", this.form.status);
      fd.append("is_head", this.form.is_head);
      axios
        .post("/app/teacher/update/" + this.$route.params.id, fd)
        .then((res) => {
          this.s(res.data.message);
          this.$router.push("/teacher");
        })
        .catch((e) => {
          if (e.response.status === 422) {
            if (e.response.data.errors.name_en) {
              this.e(e.response.data.errors.name_en[0]);
            }
            if (e.response.data.errors.teacher_image) {
              this.e(e.response.data.errors.teacher_image[0]);
            }
          }
        });
    },

    validationForm() {
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          this.add();
        }
      });
    },
  },
  computed: {
    marketplaces() {
      return this.$store.getters.getMarketplace;
    },
    departments() {
      return this.$store.getters.getDepartment;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.image_div {
  position: relative;
}
.image_delete {
  width: 25px;
  height: 25px;
  font-weight: bold;
  line-height: 25px;
  text-align: center;
  color: #fff;
  position: absolute;
  top: 5px;
  right: 30px;
  background: rgba(0, 0, 0, 0.548);
  cursor: pointer;
  border-radius: 50%;
}
</style>
